<template>
  <v-card flat title>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-icon v-if="!mobileView">mdi-card-account-details</v-icon>
      <v-toolbar-title class="ml-2" v-if="!mobileView">
        Edit Profile
      </v-toolbar-title>
      <v-chip class="ml-2" outlined pill v-if="!!data.memberId">
        Member ID: {{ data.memberId }}
      </v-chip>
      <v-spacer />
      <v-toolbar-items>
        <v-btn dark text @click="save"> Save </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <v-card-text class="pt-3">
      <v-form :disabled="loading" ref="userForm">
        <v-row>
          <v-col cols="12" sm="6" align-self="center">
            <v-list-item>
              <v-btn
                fab
                absolute
                top
                left
                x-small
                color="error"
                @click="remove"
                v-if="!!image"
                :disabled="loading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-list-item-avatar size="96">
                <v-img :src="srcImg" lazy-src="/img/default-avatar.png" />
              </v-list-item-avatar>
              <v-list-item-content class="text-center ml-3">
                <v-list-item-title class="mb-3">
                  Change your profile photo
                </v-list-item-title>
                <v-btn
                  color="primary"
                  :disabled="loading"
                  outlined
                  @click="upload"
                >
                  Upload
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="6" sm="4">
                <v-text-field
                  label="Title"
                  v-model="data.title"
                  hide-details
                  outlined
                  clearable
                  dense
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  label="First name"
                  v-model="data.firstName"
                  hide-details
                  outlined
                  clearable
                  dense
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  label="Last name"
                  v-model="data.lastName"
                  hide-details
                  outlined
                  clearable
                  dense
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
              <v-col cols="6" sm="4">
                <v-text-field
                  label="Suffix"
                  v-model="data.suffixName"
                  hide-details
                  outlined
                  clearable
                  dense
                  :disabled="!!data['virtuousId']"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Email"
              v-model="data.email"
              :rules="emailRule"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Address Line 1"
              v-model="data.address.line1"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Address Line 2"
              v-model="data.address.line2"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="City"
              v-model="data.address.city"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="4">
            <v-text-field
              label="ZIP"
              v-model="data.address.zip"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="6" sm="4">
            <v-autocomplete
              :items="states"
              item-text="name"
              item-value="acronym"
              label="State"
              v-model="state"
              :search-input.sync="searchInput"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Country"
              v-model="data.address.country"
              hide-details
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Personal Phone"
              v-model="data.personalPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Work Phone"
              v-model="data.workPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Home Phone"
              v-model="data.homePhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              clearable
              dense
              :disabled="!!data['virtuousId']"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="4">
            <v-text-field
              label="Shirt Size"
              v-model="data.shirtSize"
              hide-details
              outlined
              clearable
              dense
            />
          </v-col>
          <v-col cols="6" sm="4">
            <v-autocomplete
              :items="genderOpts"
              label="Gender"
              v-model="data.gender"
              hide-details
              outlined
              clearable
              dense
            />
          </v-col>
          <v-col cols="12" sm="4">
            <date-picker
              v-model="data.birthday"
              label="Birthday"
              @clear="data.birthday = null"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-banner color="light-blue lighten-5 px-5" v-if="data['virtuousId']">
        <v-icon slot="icon" color="primary" size="36"> mdi-link-lock </v-icon>
        Your profile has been bonded to your TBF membership #{{
          data['virtuousId']
        }}, please change your info
        <a href="https://tbf.givevirtuous.org/Account/Register">here</a>. If you
        need help, <a href="mailto:tag@billfish.org">contact us</a>!
      </v-banner>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  defaultAvatar,
  GENDER_OPTIONS,
  getBase64,
  getUserInfo,
  notifyError,
  notifyMessage,
  notifySuccess
} from '../../helpers/handler';
import DatePicker from '../../components/appComponents/DatePicker.vue';
export default {
  components: { DatePicker },
  name: 'profile',
  async mounted() {
    const user = getUserInfo();
    this.userId = user?.id;
    this.getStates();
    this.init();
  },
  data: () => ({
    userId: null,
    data: initUser,
    states: [],
    search: null,
    loading: false,
    isSelecting: false,
    image: null,
    genderOpts: GENDER_OPTIONS,
    phoneRule: [
      (v) => !v || v?.length === 14 || 'Phone number must have 10 digits'
    ],
    phoneNumberMask: '(###) ### ####',
    emailRule: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid']
  }),
  computed: {
    state: {
      get: function () {
        this.data.address.state && this.getStates(this.data.address.state[0]);
        return this.data.address.state;
      },
      set: function (v) {
        this.data.address.state = v;
      }
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (v) {
        if (v !== this.search) {
          this.search = v;
          this.getStates(v);
        }
      }
    },
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    srcImg() {
      if (typeof this.image === 'string') {
        return this.image || defaultAvatar;
      } else {
        const { mime, data } = this.image || {};
        const avatar = mime && data && `data:${mime};base64,${data}`;
        return avatar || defaultAvatar;
      }
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('query', ['getItems']),
    ...mapActions('entry', ['getAvatar', 'uploadAvatar', 'deleteAvatar']),
    cancel() {
      this.$emit('submit', null);
    },
    save() {
      const validForm = this.$refs.userForm && this.$refs.userForm.validate();
      if (validForm) {
        this.$emit('submit', { ...this.data, _id: this.userId });
      }
    },
    async init() {
      this.loading = true;
      const { data: avatar } = (await this.getAvatar()) || {};
      this.image = avatar?.docs && JSON.parse(avatar.docs)?.image;
      const { data: userData } =
        (await this.getUser({ id: this.userId })) || {};
      this.loading = false;
      if (userData) {
        this.phoneNumberMask = '';
        Object.keys(this.data).forEach((key) => {
          if (key === 'address') {
            userData?.address &&
              Object.keys(this.data.address || {}).forEach((innerKey) => {
                this.data.address[innerKey] = userData?.address[innerKey];
              });
          } else {
            this.data[key] = userData[key];
          }
        });

        this.$nextTick(() => {
          this.phoneNumberMask = '(###) ### ####';
        });

        return;
      }

      notifyMessage("Can't able to get the user info");
    },
    async getStates(name = '') {
      this.states = [];
      const payload = { name, item: 'states', page: 1, limit: 10 };
      const { data: states } = (await this.getItems(payload)) || {};
      if (states?.docs) {
        this.states = states.docs;
        return;
      }

      notifyMessage("Can't able to get states");
    },
    upload() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader && this.$refs.uploader.click();
    },
    async remove() {
      this.loading = true;
      const { data } = (await this.deleteAvatar()) || {};
      this.loading = false;
      if (data?.success) {
        notifySuccess('Picture successfully removed');
        this.image = null;
        this.$emit('update', this.image);
        return;
      }

      notifyError('Something went wrong');
    },
    async onFileChanged(e) {
      const file = e?.target?.files[0];
      if (file) {
        this.loading = true;

        const mime = file?.type;
        const image = await getBase64(file);
        const data = String(image).split(',')[1];

        const { data: uploaded } =
          (await this.uploadAvatar({ mime, data })) || {};

        this.loading = false;

        if (uploaded?.success) {
          notifySuccess('Picture successfully uploaded');
          this.image = image;
          this.$emit('update', this.image);
          return;
        }

        notifyError('Something went wrong');
      }
    }
  }
};

const initUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: null,
    zip: null
  },
  title: null,
  suffixName: null,
  shirtSize: null,
  gender: null,
  personalPhone: null,
  birthday: null,
  memberId: null,
  workPhone: null,
  homePhone: null,
  virtuousId: null,
  rewardsConfig: {
    receiveCertifications: true,
    receiveRewards: true,
    notes: '',
    updatedAt: null
  }
};
</script>
