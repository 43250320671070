<template>
  <v-app-bar app clipped-left clipped-right color="indigo" dark>
    <!---Logo part -->
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <img
          alt="Billfish"
          width="50"
          height="50"
          src="/img/billfish-logo.png"
        />
      </span>
      <span class="logo-text ml-2" :class="`${showLogo ? '' : 'hidelogo'}`">
        <!--<img src="../../../assets/images/logo-light-text.png" class="mt-2" />-->
        Administration
      </span>
    </v-toolbar-title>
    <!---Logo part -->
    <!---/Toggle sidebar part -->
    <!-- <div @click="showhideLogo">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? SET_SIDEBAR_DRAWER(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
    </div> -->
    <v-btn icon @click="toogle">
      <v-icon>
        {{ `mdi-${showLogo ? 'back' : 'forward'}burger` }}
      </v-icon>
    </v-btn>
    <!---/Toggle sidebar part -->
    <v-spacer />
    <!---right part -->
    <!---User -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <v-img :src="srcImg" lazy-src="/img/default-avatar.png" />
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          @click="launch(item.title)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from 'vuex';
import { defaultAvatar } from '../../../helpers/handler';
export default {
  name: 'Header',

  components: {},

  props: {
    id: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    image: null,
    // notifications: [
    //   {
    //     title: 'Launch Admin',
    //     iconbg: 'error',
    //     icon: 'mdi-link-variant',
    //     desc: 'Just see the my new admin!',
    //     time: '9:30AM'
    //   }
    // ],
    // messages: [
    //   {
    //     title: 'Sonu Nigam',
    //     avatar: '1',
    //     avatarstatus: 'success',
    //     desc: 'Singing Show tonigh at 9pm!',
    //     time: '9:30AM'
    //   }
    // ],
    userprofile: [{ title: 'My Profile' }, { title: 'Logout' }],
    href() {
      return undefined;
    }
  }),
  computed: {
    ...mapState('sidebar', ['Sidebar_drawer']),
    srcImg() {
      if (typeof this.image === 'string') {
        return this.image || defaultAvatar;
      } else {
        const { mime, data } = this.image || {};
        const avatar = mime && data && `data:${mime};base64,${data}`;
        return avatar || defaultAvatar;
      }
    }
  },
  watch: {
    Sidebar_drawer: {
      handler: 'sidebarChanges',
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations('sidebar', ['SET_SIDEBAR_DRAWER']),
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    launch(v) {
      if (v === 'Logout') {
        localStorage.removeItem('data');
        this.$router.push({ path: '/login' });
      } else if (v === 'My Profile') {
        this.$emit('profile');
      }
    },
    updateAvatar(img) {
      this.image = img;
    },
    toogle() {
      this.showLogo = !this.showLogo;
      this.$vuetify.breakpoint.smAndDown
        ? this.SET_SIDEBAR_DRAWER(!this.Sidebar_drawer)
        : this.$emit('input', !this.value);
    },
    sidebarChanges(v) {
      this.showLogo = v;
    }
  }
};
</script>

<style lang="scss">
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
.logo-icon {
  cursor: pointer !important;
}
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
