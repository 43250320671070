export default [
  {
    icon: 'mdi-text-box-search',
    title: 'Search',
    to: '/searches'
  },
  {
    icon: 'mdi-account-multiple',
    title: 'Manage Users',
    to: '/users'
  },
  {
    icon: 'mdi-square-edit-outline',
    title: 'Edit Items',
    to: '/edit'
  },
  {
    icon: 'mdi-chart-box',
    title: 'Analytics',
    to: '/analytics'
  },
  {
    icon: 'mdi-account-alert',
    title: 'Confirmations',
    to: '/confirmations'
  },
  {
    icon: 'mdi-certificate',
    title: 'Certificates',
    to: '/certificates'
  },
  {
    icon: 'mdi-tag-text',
    title: 'Tag Issuance',
    to: '/issuance'
  },
  {
    icon: 'mdi-trophy',
    title: 'Competitions',
    to: '/competition'
  },
  {
    icon: 'mdi-content-duplicate',
    title: 'Find duplicates',
    to: '/duplicates'
  },
  {
    icon: 'mdi-card-search',
    title: 'Find Milestones',
    to: '/trophies'
  },
  {
    icon: 'mdi-shield-check',
    title: 'Privacy Policy',
    to: '/privacy'
  },
  {
    icon: 'mdi-wallet-membership',
    title: 'Clear member IDs',
    to: '/membership'
  },
  {
    icon: 'mdi-chart-timeline-variant',
    title: 'Activities Report',
    to: '/activities'
  },
  {
    icon: 'mdi-email-newsletter',
    title: 'Email Templates',
    to: '/email-templates'
  }
];
