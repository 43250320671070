<template>
  <v-dialog
    v-model="open"
    max-width="750px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-card>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> TBF Reward </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="white"
          class="text--primary"
          @click="submit"
          :loading="loading"
          :disabled="loading || !allReady || !recipientCount"
        >
          SUBMIT
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-banner>
              We at TBF cannot thank you enough for reporting this recapture to
              us! Referred to "closing the scientific loop", recaptures are the
              most valuable data we receive. As a thank you, we'd love to send
              you a TBF t-shirt as a reward! If you're interested, please
              provide a name and address for all those involved and we'd be
              happy to send a reward your way.
            </v-banner>
          </v-col>

          <transition name="fade">
            <v-col cols="12" v-if="showSingleLocation">
              <v-row no-gutters>
                <v-checkbox
                  v-model="singleLocation"
                  label="Send all the rewards to a single location?"
                  hide-details
                  class="mt-0 shrink"
                >
                </v-checkbox>
                <v-tooltip max-width="640" bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-variant-circle
                    </v-icon>
                  </template>
                  <span class="caption">
                    We understand that you might want to simplify the process of
                    receiving your rewards. To make it easier, we offer the
                    option to ship all the rewards to a single location. Please
                    note that by choosing this option, you are confirming that
                    the provided address will receive rewards for all the
                    recipients. Ensure the address is accurate and accessible to
                    avoid any delays or lost shipments.
                  </span>
                </v-tooltip>
              </v-row>
            </v-col>
          </transition>

          <v-fade-transition>
            <v-col cols="12" v-if="singleLocation">
              <v-textarea
                auto-grow
                rows="1"
                label="Address?"
                v-model="rewardNote"
                outlined
                clearable
                dense
                counter
                :rules="addressRule"
              />
            </v-col>
          </v-fade-transition>

          <transition name="fade">
            <v-col cols="12" v-if="!recipientCount">
              <v-banner color="amber lighten-5">
                <v-icon slot="icon" color="warning" size="36">
                  mdi-alert
                </v-icon>
                <h5>
                  It's required to add/activate one or more recipients to submit
                  the rewards. <br />
                  If you don't want to receive any reward, please dismiss this
                  form by clicking the "X" in the top left corner.
                </h5>
              </v-banner>
            </v-col>
          </transition>

          <v-col cols="12" v-if="this.rewards.length">
            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel
                v-for="(item, i) of rewards"
                :key="i"
                :readonly="!item.enabled"
                ref="rewardPanel"
              >
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters class="align-center">
                      <v-col class="row align-center">
                        <v-checkbox
                          hide-details
                          class="shrink mt-0"
                          v-model="item.enabled"
                          @click.stop
                          @change="(v) => askRemove(i, v)"
                          :disabled="!item.name"
                        ></v-checkbox>
                        <span v-if="!item.isNew">
                          {{ item.name }}
                        </span>
                        <v-text-field
                          v-else
                          v-model="item.name"
                          dense
                          hide-details
                          :rules="[(v) => !!v || 'Required']"
                          @click.stop
                          @keyup.prevent="onNameTyping(i)"
                        ></v-text-field>
                      </v-col>
                      <v-col class="text--secondary text-right pr-2">
                        <v-fade-transition leave-absolute>
                          <span v-if="item.enabled">
                            <span v-if="open" class="primary--text">
                              Click here to submit
                            </span>
                            <span v-else>
                              <span v-if="item.ready"> Submitted </span>
                              <span v-else> Click here to take action </span>
                            </span>
                          </span>
                          <span v-else>
                            <span v-if="item.name"> Discarded </span>
                            <span v-else> Add recipient name </span>
                          </span>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:actions="{ open }">
                    <v-icon color="primary" v-if="item.enabled && open">
                      $expand
                    </v-icon>
                    <v-icon
                      color="green"
                      v-if="item.ready && !open && item.enabled"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      color="error"
                      v-if="!item.ready && !open && item.enabled"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                    <v-tooltip
                      max-width="320"
                      bottom
                      color="primary"
                      v-if="!item.enabled && Boolean(item.name) && !open"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">
                          mdi-information-variant-circle
                        </v-icon>
                      </template>
                      <span class="caption">
                        Hit the checkbox to activate the recipient. Once
                        activated, you can add the reward details and submit the
                        form.
                      </span>
                    </v-tooltip>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="rewardForm">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <p class="mb-0">
                          If you choose not to receive any reward including the
                          certificate and report, please indicate here:
                        </p>
                      </v-col>

                      <v-col cols="12">
                        <v-radio-group
                          v-model="item.optionSelected"
                          class="mt-0"
                          hide-details
                        >
                          <v-radio
                            v-for="option in options"
                            :key="option.label"
                            :label="option.label"
                            :value="option.value"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-fade-transition>
                        <div
                          class="flex"
                          v-if="
                            item.optionSelected === 'both' ||
                            item.optionSelected === 'no-certificate'
                          "
                        >
                          <v-col cols="12" class="pb-0">
                            <p class="mb-0">
                              What size would you like your t-shirt?
                            </p>
                          </v-col>

                          <v-col
                            cols="12"
                            md="3"
                            sm="4"
                            v-if="
                              item.optionSelected === 'both' ||
                              item.optionSelected === 'no-certificate'
                            "
                          >
                            <v-autocomplete
                              :items="['XS', 'S', 'M', 'L', 'XL', 'XXL']"
                              label="Shirt Size"
                              v-model="item.shirtSize"
                              hide-details
                              outlined
                              dense
                              :rules="shirtSizeRules[i]"
                            />
                          </v-col>
                        </div>
                      </v-fade-transition>

                      <v-fade-transition>
                        <v-col cols="12" v-if="addressRequired[i]">
                          <v-row no-gutters>
                            <v-textarea
                              auto-grow
                              rows="1"
                              label="Address?"
                              v-model="item.rewardNote"
                              outlined
                              clearable
                              dense
                              counter
                              :rules="notesRules[i]"
                            />
                            <v-tooltip max-width="240" top color="primary">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="ml-2"
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-information-variant-circle
                                </v-icon>
                              </template>
                              <span class="caption">
                                Ensure the address is accurate and accessible to
                                avoid any delays or lost shipments.
                              </span>
                            </v-tooltip>
                          </v-row>
                        </v-col>
                      </v-fade-transition>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12">
            <v-btn
              block
              text
              depressed
              class="primary"
              style="border-radius: 0"
              @click="addMore"
              v-if="rewards.length < 5"
              :disabled="loading"
            >
              Add a new recipient
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { getUserInfo } from '../../../../helpers/user';
import {
  notifyConfirmation,
  notifyError,
  notifyMessage
} from '../../../../helpers/notifications';

export default {
  name: 'reward-form',
  data: () => ({
    rewardNote: '',
    options: [
      {
        label: 'I would like to receive a t-shirt and certificate/report',
        value: 'both'
      },
      {
        label: 'I would like to receive only a t-shirt (no certificate)',
        value: 'no-certificate'
      },
      {
        label: 'I would like to receive only a certificate (no t-shirt)',
        value: 'no-reward'
      },
      { label: 'I would not like a t-shirt or certificate', value: 'none' }
    ],
    loading: false,
    singleLocation: false,
    panels: [],
    rewards: [],
    open: false,
    recordId: null
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    addressRequired() {
      const required = [];

      // item.optionSelected !== 'none' && !singleLocation
      for (const recipient of this.rewards) {
        const { optionSelected } = recipient || {};

        if (!this.singleLocation && optionSelected !== 'none') {
          required.push(true);
          continue;
        }

        required.push(false);
      }

      return required;
    },
    shirtSizeRules() {
      const defaultRule = [(v) => !!v || 'Required'];
      const rules = [];

      for (const recipient of this.rewards) {
        const { enabled, optionSelected } = recipient || {};

        if (
          optionSelected === 'no-reward' ||
          optionSelected === 'none' ||
          !enabled
        ) {
          rules.push([]);
          continue;
        }

        rules.push(defaultRule);
      }

      return rules;
    },
    notesRules() {
      const defaultRule = [
        (v) => !v || v?.length <= 300 || 'Max 300 characters',
        (v) => !v || v?.length >= 5 || 'Min 5 characters'
      ];
      const rules = [];

      for (const recipient of this.rewards) {
        const { enabled, optionSelected } = recipient || {};

        if (this.singleLocation || optionSelected === 'none' || !enabled) {
          rules.push([...defaultRule]);
          continue;
        }

        rules.push([(v) => Boolean(v) || 'Required', ...defaultRule]);
      }

      return rules;
    },
    addressRule() {
      const defaultRule = [
        (v) => !v || v?.length <= 300 || 'Max 300 characters',
        (v) => !v || v?.length >= 5 || 'Min 5 characters'
      ];

      if (!this.singleLocation) {
        return [...defaultRule];
      }

      return [(v) => Boolean(v) || 'Required', ...defaultRule];
    },
    allReady() {
      if (this.singleLocation && !this.rewardNote) {
        return false;
      }

      if (!this.rewards || !this.rewards.length) {
        return false;
      }

      return this.rewards.every((_, index) => {
        // this.closePanel(index);
        const { enabled, ready } = this.rewards[index];
        return !enabled || (enabled && ready);
      });
    },
    recipientCount() {
      return this.rewards.filter(({ enabled }) => enabled).length;
    },
    showSingleLocation() {
      if (this.recipientCount <= 1) {
        return false;
      }

      return true;
      // (
      //   this.rewards.filter(
      //     ({ enabled, optionSelected }) => enabled && optionSelected !== 'none'
      //   ).length > 1
      // );
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('entry', ['submitReward']),
    close() {
      if (this.loading) {
        this.loading = false;
      }

      this.rewards = [];
      this.open = false;
    },
    async submit() {
      this.loading = true;

      try {
        const rewardsPayload = [];

        for (const reward of this.rewards) {
          const { ready, enabled } = reward || {};

          if (!enabled || !ready) {
            continue;
          }

          const { id, optionSelected, shirtSize, rewardNote, name } =
            reward || {};

          rewardsPayload.push({
            userId: id,
            name,
            shirtSize,
            notes: rewardNote,
            receiveCertifications:
              optionSelected === 'no-reward' || optionSelected === 'both',
            receiveRewards:
              optionSelected === 'no-certificate' || optionSelected === 'both'
          });
        }

        const response = await this.submitReward({
          rewards: rewardsPayload,
          rewardNote: this.rewardNote,
          recordId: this.recordId
        });
        const { success } = response?.data || {};

        if (!success) {
          notifyError('Failed to submit rewards');
          return;
        }

        this.close();
      } catch {
        notifyError('Failed to submit rewards');
        this.loading = false;
      }
    },
    closePanel(index) {
      const { rewardForm } = this.$refs || {};
      if (!rewardForm || !rewardForm[index]) {
        return;
      }

      const { validate } = rewardForm[index] || {};
      this.rewards[index].ready = validate && validate();
    },
    resetReady(index) {
      const { rewardForm } = this.$refs || {};
      if (!rewardForm || !rewardForm[index]) {
        return;
      }

      this.rewards[index].ready = false;
    },
    addMore() {
      const validRecipients = this.rewards.every(
        ({ isNew, name }) => !isNew || Boolean(name)
      );

      if (!validRecipients) {
        notifyMessage('Please complete the current recipient');
        return;
      }

      this.rewards.push({
        enabled: false,
        name: null,
        optionSelected: 'none',
        shirtSize: null,
        rewardNote: null,
        isNew: true,
        id: null,
        ready: false
      });
    },
    askRemove(index, value) {
      if (value) {
        return;
      }

      const { isNew } = this.rewards[index] || {};
      if (!isNew) {
        this.panels = this.panels.filter((i) => i !== index);
        return;
      }

      notifyConfirmation(
        'Are you want to remove it?',
        'Discard new recipient',
        () => {
          if (this.$refs.rewardForm && this.$refs.rewardForm[index]) {
            this.$refs.rewardForm[index].reset();
          }

          // this.panels = [];
          this.rewards.splice(index, 1);
        },
        () => {
          // this.panels = [];
          this.panels.splice(index, 1);
        },
        'Yes, remove it',
        'No, keep it',
        false
      );
    },
    async init(recordId = '', crew = []) {
      if (!recordId) {
        return;
      }

      this.singleLocation = false;
      this.recordId = recordId;
      this.rewards = [];
      this.loading = true;

      try {
        for (const recipient of crew || []) {
          const response = await this.getUser({ id: recipient });
          const { data: userData } = response || {};
          const { shirtSize, rewardsConfig, address, firstName, lastName } =
            userData || {};
          const { receiveCertifications, receiveRewards, notes } =
            rewardsConfig || {};
          const { line1, city, state, zip, country } = address || {};
          const addressNote = [line1, city, state, zip, country]
            .filter(Boolean)
            .join(' ');

          let optionSelected = 'none';

          if (receiveCertifications && receiveRewards) {
            optionSelected = 'both';
          } else if (receiveCertifications) {
            optionSelected = 'no-reward';
          } else if (receiveRewards) {
            optionSelected = 'no-certificate';
          }

          this.rewards.push({
            id: recipient,
            enabled: true,
            name: [firstName, lastName].filter(Boolean).join(' '),
            optionSelected,
            shirtSize: shirtSize || 'XL',
            rewardNote: notes || addressNote,
            ready: false,
            isNew: false
          });
        }

        const { id: userId } = getUserInfo();
        const response = await this.getUser({ id: userId });
        const { address } = response?.data || {};
        const { line1, city, state, zip, country } = address || {};
        this.rewardNote = [line1, city, state, zip, country]
          .filter(Boolean)
          .join(' ');

        this.panels = this.rewards.map((_, i) => i);
      } catch {
        this.rewards = [];
      }

      this.loading = false;

      this.open = true;
    },
    onNameTyping(index) {
      const { name, enabled } = this.rewards[index] || {};
      const panelIncluded = this.panels.includes(index);

      if (!name) {
        if (panelIncluded) {
          this.panels = this.panels.filter((i) => i !== index);
        }

        if (enabled) {
          this.rewards[index].enabled = false;
        }

        return;
      }

      this.rewards[index].name = name.toUpperCase();

      if (!enabled) {
        this.rewards[index].enabled = true;
      }

      if (panelIncluded) {
        return;
      }

      this.panels.push(index);
    }
  },
  watch: {
    singleLocation: {
      handler: function () {
        if (this.$refs.rewardForm) {
          this.$refs.rewardForm.forEach((form, i) => {
            this.rewards[i].ready = false;
          });
        }
      }
    },
    panels: {
      handler: function (newValue, prevValue) {
        prevValue.forEach((index) => {
          if (!newValue.includes(index)) {
            this.closePanel(index);
          }
        });

        newValue?.forEach((index) => {
          if (!prevValue?.includes(index)) {
            this.resetReady(index);
          }
        });
      }
    }
  }
};
</script>
