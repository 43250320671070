<template>
  <v-row class="text-center mr-8">
    <v-chip
      :color="item.color"
      label
      v-for="(item, i) in items"
      :key="i"
      text-color="white"
      :class="
        vertical
          ? 'block gap justify-space-between'
          : 'block set-rect justify-center'
      "
      :small="full"
    >
      <span class="mr-2" v-html="vertical ? item.text : shortName(item.text)" />
      <v-chip
        v-html="amountPerItems[item.amount] || defaultAmount"
        :color="`${item.color} lighten-5`"
        :class="`${item.color}--text font-weight-bold`"
        :x-small="full"
        :small="!full"
      />
    </v-chip>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'statistics',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [
      {
        color: 'primary',
        icon: 'mdi-tag-text',
        text: 'TOTAL TAGS',
        amount: 'tags'
      },
      {
        color: 'primary',
        icon: 'mdi-book-clock',
        text: 'TAGS LAST YEAR',
        amount: 'tagsLastYear'
      },
      {
        color: 'green',
        icon: 'mdi-fish',
        text: 'TOTAL RELEASES',
        amount: 'releases'
      },
      {
        color: 'green',
        icon: 'mdi-book-clock',
        text: 'RELEASES LAST YEAR',
        amount: 'releasesLastYear'
      },
      {
        color: 'orange',
        icon: 'mdi-fishbowl-outline',
        text: 'TOTAL RECAPTURES',
        amount: 'recaptures'
      },
      {
        color: 'orange',
        icon: 'mdi-book-clock',
        text: 'RECAPTURES LAST YEAR',
        amount: 'recapturesLastYear'
      },
      {
        color: 'red',
        icon: 'mdi-account-group',
        text: 'TOTAL USERS',
        amount: 'users'
      }
    ],
    show: false,
    defaultAmount: 0,
    amountPerItems: {}
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  async mounted() {
    await this.getData();
  },
  watch: {
    open: {
      handler: 'launch',
      immediate: true
    }
  },
  methods: {
    ...mapActions('query', ['getStatistics']),
    async getData() {
      this.$emit('loading', true);
      const { data } = await this.getStatistics();
      this.$emit('loading', false);
      if (!data?.docs) {
        return;
      }
      this.amountPerItems = Array.isArray(data?.docs)
        ? this.migrateData(data.docs)
        : data?.docs;
    },
    migrateData(array = []) {
      if (!array?.length) {
        return {};
      }

      const data = {};
      array.forEach(item => {
        Object.keys(item).forEach(key => {
          data[key] = item[key];
        });
      });

      return data;
    },
    launch(value) {
      value && (this.show = value);
    },
    shortName(value = '') {
      if (!value) return value;
      const array = String(value).split(' ');
      if (!array?.length) return value;
      return String(value).slice(array[0].length + 1, value.length);
    }
  }
};
</script>

<style>
.set-rect {
  border-radius: 0px !important;
}

.block {
  flex-grow: 1;
}

.gap {
  margin-bottom: 5px;
}

.gap span[class~='v-chip__content'] {
  display: contents !important;
}
</style>
