<template>
  <v-footer app class="footerpart px-5" inset>
    <span
      >© {{ new Date().getFullYear() }} — The Billfish Foundation | All Rights
      Reserved</span
    >
    <v-spacer />
    <v-speed-dial
      v-model="statistics"
      direction="top"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn text :loading="loading">
          <v-icon left>mdi-chart-box</v-icon>
          Statistics
        </v-btn>
      </template>
      <template v-slot:default>
        <statistics open vertical @loading="(value) => (loading = value)" />
      </template>
    </v-speed-dial>
  </v-footer>
</template>
<script>
import Statistics from '../../../views/analytics/Statistics.vue';
export default {
  components: { Statistics },
  name: 'Footer',
  data: () => ({
    statistics: false,
    loading: false,
    building: false
  })
};
</script>
<style lang="scss">
.v-application .footerpart {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.theme--light.v-footer {
  background: white;
}
</style>
