<template>
  <v-dialog v-model="showDialog" scrollable fullscreen persistent>
    <v-dialog v-model="openEdit" persistent scrollable max-width="1080px">
      <v-card title>
        <v-toolbar flat dark color="primary" max-height="100px">
          <v-btn icon dark @click="openEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Edit Tag </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed color="white" text @click="submitRecord">
            Save
            <!-- <v-icon>mdi-close</v-icon> -->
          </v-btn>
        </v-toolbar>
        <v-card-text ref="contentCard">
          <tag-form ref="tagsForm" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card title>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title>
        <v-icon class="mr-5">mdi-tag-multiple</v-icon>
        <h3>Submit Tags</h3>
        <v-spacer />
        <v-chip color="red" outlined v-if="records.length && erroredRows">
          <v-checkbox
            v-model="onlyErrored"
            :label="`Errored rows (${erroredRows})`"
            color="red"
            class="font-weight-thin text-caption red-text"
          ></v-checkbox>
        </v-chip>
        <v-chip
          class="mx-1"
          color="orange"
          outlined
          v-else-if="records.length && unconfirmedRows"
        >
          <v-checkbox
            v-model="onlyUnconfirmed"
            :label="`Unconfirmed rows (${unconfirmedRows})`"
            color="orange"
            class="font-weight-thin text-caption orange--text"
          ></v-checkbox>
        </v-chip>
        <v-btn v-on:click="showDialog = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4 text-center" v-if="!records.length">
        <div
          class="import-wrapper p-0 p-md-16"
          @drop.prevent="onDrop"
          @dragover.prevent
          @dragenter.prevent
        >
          <p class="text-h5 font-weight-thin">
            Upload the file by dragging and dropping into the dashed area or
            click the below button
          </p>
          <v-btn class="primary" @click="chooseFiles">Upload file</v-btn>
          <input
            class="hidden"
            id="fileUpload"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileInputChange"
          />

          <div class="font-weight-thin">
            <v-banner>
              <v-icon slot="icon" color="primary" size="36">
                mdi-alert-circle
              </v-icon>
              Remember to download the latest
              <v-btn @click="exportTemplate" text color="primary">
                Template File
              </v-btn>
            </v-banner>
            <span>
              Microsoft Excel is recommended for the best experience in viewing
              and editing the file
            </span>
            <v-spacer></v-spacer>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else class="list-container py-5">
        <v-card
          v-for="(data, index) in records"
          v-bind:key="index"
          :class="{
            'd-none':
              (erroredRows && onlyErrored && !haveError(index)) ||
              (unconfirmedRows && onlyUnconfirmed && isConfirmed(index))
          }"
        >
          <v-progress-linear
            value="100"
            :color="
              haveError(index)
                ? 'red'
                : isConfirmed(index)
                ? undefined
                : 'orange'
            "
          ></v-progress-linear>
          <v-card-text>
            <div class="row-indicator white--text">
              <v-chip
                class="mr-2 btn-chip"
                color="primary"
                @click="() => editRecord(index)"
              >
                Edit
              </v-chip>
              <v-chip
                class="white--text"
                :color="haveError(index) ? 'red' : 'primary'"
              >
                ROW #{{ index + 3 }}
              </v-chip>
            </div>
            <div
              class="row-confirmation"
              v-if="!haveError(index) && !isConfirmed(index)"
            >
              <v-btn
                class="white--text font-weight-thin"
                color="orange"
                @click="
                  selectedData = { ...data, index };
                  openVerification = true;
                "
              >
                Confirm Data
              </v-btn>
            </div>
            <v-row>
              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Tag Number"
                      v-model="data.number"
                      :error="haveError(index, 'tag_number')"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Ocean"
                      v-model="data.capture.ocean"
                      :error="haveError(index, 'ocean')"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Species"
                      v-model="data.capture.fish.specie.name"
                      :error="haveError(index, 'species')"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Tag Date"
                      v-model="data.createdDate"
                      :error="haveError(index, 'tag_date')"
                      type="date"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Entered Date"
                      :value="data.enteredDate"
                      type="date"
                    />
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Coordinates"
                      :value="getCoordinates(data)"
                      :error="haveError(index, 'coordinates')"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Location"
                      v-model="data.coordinates.description"
                      :error="haveError(index, 'location')"
                      :needConfirmation="!haveError(index)"
                      :confirmed="isConfirmed(index, 'location')"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Fish Length"
                      :value="getFishStat(data.capture.fish.length)"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Fish Weight"
                      :value="getFishStat(data.capture.fish.weight)"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Bait"
                      :value="data.capture.bait.name"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Fish Condition"
                      :value="data.capture.fish.condition.name"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Fight Time"
                      :value="getFightTime(data)"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Hook Type"
                      :value="data.capture.hook.name"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Gear Type"
                      :value="data.capture.gear.name"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Tournament"
                      :value="data.capture.tournament.name"
                      :needConfirmation="!haveError(index)"
                      :confirmed="isConfirmed(index, 'tournament')"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Boat"
                      :value="data.boat?.name || data.boatName"
                      :needConfirmation="!haveError(index)"
                      :confirmed="isConfirmed(index, 'boat')"
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Shark Encounter"
                      :value="data.sharkEncounter ? 'YES' : 'NO'"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Angler"
                      :value="data.angler"
                      :error="haveError(index, 'angler')"
                      type="user"
                      @new="
                        email = data.angler.id;
                        sheet = true;
                      "
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Captain"
                      :value="data.captain"
                      :error="haveError(index, 'captain')"
                      type="user"
                      @new="
                        email = data.captain.id;
                        sheet = true;
                      "
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="First Mate"
                      :value="data.firstMate"
                      :error="haveError(index, 'firstMate')"
                      type="user"
                      @new="
                        email = data.firstMate.id;
                        sheet = true;
                      "
                    ></list-item>
                  </v-col>

                  <v-col class="py-0">
                    <list-item
                      label="Second Mate"
                      :value="data.secondMate"
                      :error="haveError(index, 'secondMate')"
                      type="user"
                      @new="
                        email = data.secondMate.id;
                        sheet = true;
                      "
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-row>
                  <v-col class="py-0">
                    <list-item
                      label="Notes"
                      v-model="data.capture.notes"
                    ></list-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-progress-linear
            value="100"
            :color="
              haveError(index)
                ? 'red'
                : isConfirmed(index)
                ? undefined
                : 'orange'
            "
          ></v-progress-linear>
        </v-card>
      </v-card-text>

      <v-bottom-sheet
        v-model="sheet"
        persistent
        :fullscreen="full"
        :scrollable="full"
      >
        <new-user :email="email" @close="closeNewUserForm" />
      </v-bottom-sheet>

      <item-verification
        :open="openVerification"
        @close="
          openVerification = false;
          selectedData = null;
        "
        :value="selectedData"
        :confirmedLocation="isConfirmed(selectedData?.index, 'location')"
        :confirmedBoat="isConfirmed(selectedData?.index, 'boat')"
        :confirmedTournament="isConfirmed(selectedData?.index, 'tournament')"
        @done="confirmData"
      ></item-verification>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="exportTemplate" text outlined color="primary">
          <v-icon left>mdi-file-download</v-icon>
          Template File
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          outlined
          color="primary"
          @click="chooseFiles"
          v-if="!records.length"
        >
          UPLOAD FILE
        </v-btn>
        <v-btn text outlined color="red" @click="clearData" v-else>
          <v-icon left>mdi-file-document-remove-outline</v-icon>
          CLEAR DATA
        </v-btn>
        <v-btn
          text
          outlined
          color="primary"
          :disabled="!!unconfirmedRows"
          v-if="records.length && !erroredRows"
          @click="submitBatch"
        >
          SUBMIT
        </v-btn>
        <v-btn
          @click="exportSummary"
          text
          outlined
          color="red"
          v-else-if="records.length && erroredRows"
        >
          <v-icon left>mdi-download</v-icon>
          Summary
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import * as ExcelJS from 'exceljs';
import {
  notifyConfirmation,
  notifyError
} from '../../../helpers/notifications';
import {
  fixTimezone,
  formatDate,
  getFile,
  selectSheetRange
} from '../../../helpers/format';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel
} from '../viewmodels';
import { validCoordinates } from '../../../helpers/map';
import ConfirmationListItem from '../../../components/appComponents/ConfirmationListItem.vue';
import moment from 'moment';
import NewUser from '../forms/sections/NewUser.vue';
// import NewLocation from '../forms/sections/NewLocation.vue';
import BatchItemVerification from './sections/BatchItemVerification.vue';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import TagForm from './../forms/sections/TagForm.vue';

export default {
  name: 'batch-import',
  components: {
    'list-item': ConfirmationListItem,
    'new-user': NewUser,
    // 'new-location': NewLocation,
    'item-verification': BatchItemVerification,
    'tag-form': TagForm
  },
  data: () => ({
    showDialog: false,
    records: [],
    overlay: false,
    showTemplateTooltip: false,
    errors: [],
    userEmails: [],
    sheet: false,
    email: null,
    fileName: null,
    onlyErrored: false,
    onlyUnconfirmed: false,
    openVerification: false,
    selectedData: null,
    confirmedRecords: [],
    openEdit: false
  }),
  watch: {
    showDialog: {
      handler: 'resetRecords'
    }
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    erroredRows() {
      return [...new Set(this.errors.map(({ row }) => row))].length;
    },
    unconfirmedRows() {
      return (
        this.records.length -
        this.confirmedRecords.filter(({ description }) => description === 'LBT')
          .length
      );
    }
  },
  methods: {
    ...mapActions('entry', ['checkEvent', 'addTag']),
    ...mapActions('query', ['getTemplate', 'getUsers']),
    ...mapActions('users', ['getExistentUsers']),

    async closeNewUserForm() {
      this.email = null;
      this.sheet = false;
      await this.assignRecords();
    },

    clearData() {
      notifyConfirmation(
        'Are you sure to proceed, this will clear your submission',
        'Clear Data',
        () => {
          this.records = [];
        },
        () => {},
        'Proceed'
      );
    },

    async confirmData(value) {
      this.openVerification = false;
      this.overlay = true;
      const { location, boat, tournament, description } = value || {};

      if (!description) {
        this.selectedData = null;
        this.overlay = false;
        return;
      }

      const prevBoatName = this.selectedData.boatName;
      const prevTournamentName = this.selectedData.capture.tournament?.name;

      const records = this.records.map((record, index) => {
        const confirmedRecord = this.isConfirmed(index);

        if (confirmedRecord) {
          return record;
        }

        if (index === this.selectedData.index) {
          if (location) {
            record.locality = location;
            record.coordinates.description = location;
          }

          if (boat) {
            record.boatName = boat;
            record.boat.name = boat;
          }

          if (tournament) {
            record.capture.tournament.name = tournament;
          }

          this.confirmedRecords.push({
            index: this.selectedData.index,
            description
          });

          return record;
        }

        const { coordinates, boatName, capture } = record;
        const isConfirmedLocation = this.isConfirmed(index, 'location');
        const sameLatitude = Object.keys(coordinates.latitude).every(
          (key) =>
            coordinates.latitude[key] ===
            this.selectedData.coordinates.latitude[key]
        );
        const sameLongitude = Object.keys(coordinates.longitude).every(
          (key) =>
            coordinates.longitude[key] ===
            this.selectedData.coordinates.longitude[key]
        );

        if (!isConfirmedLocation && sameLatitude && sameLongitude && location) {
          record.coordinates.description = location;
          record.locality = location;
        }

        const isConfirmedBoat = this.isConfirmed(index, 'boat');
        const matchBoat = prevBoatName === boatName;
        if (!isConfirmedBoat && prevBoatName && matchBoat && boat) {
          record.boatName = boat;
          record.boat.name = boat;
        }

        const isConfirmedTournament = this.isConfirmed(index, 'tournament');
        const matchTournament =
          prevTournamentName === capture?.tournament?.name;
        if (
          !isConfirmedTournament &&
          prevTournamentName &&
          matchTournament &&
          tournament
        ) {
          record.capture.tournament.name = tournament;
        }

        return record;
      });

      await this.assignRecords(records);

      this.selectedData = null;
      this.overlay = false;
    },

    async exportTemplate() {
      this.overlay = true;
      try {
        const response = await this.getTemplate();
        const { data } = response || {};
        if (!data) {
          notifyError('Unable to build the tag batch template');
          this.overlay = false;
          return;
        }
        getFile(data, 'xlsx', 'Tag Batch Template');
      } catch (error) {
        notifyError('Unable to build the tag batch template');
      }
      this.overlay = false;
    },

    resetRecords(value) {
      if (!value) {
        this.records = [];
      } else {
        this.showTemplateTooltip = true;
      }
    },

    onFileInputChange(event) {
      const { target } = event || {};
      const [file] = target?.files || [];

      this.handleFile(file);
    },

    onDrop(event) {
      const { dataTransfer } = event || {};
      const [file] = dataTransfer?.files || [];

      this.handleFile(file);
    },

    handleFile(file) {
      if (!file) {
        return;
      }

      const [extension] = file.name?.split('.').reverse() || [];

      if (!extension || extension.toLowerCase() !== 'xlsx') {
        notifyError('Unsupported file type, only Excel files allowed');
        return;
      }

      this.fileName = file.name;
      this.overlay = true;

      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const { result } = readerEvent.target;

        if (!result) {
          notifyError('File ' + file.name + ' is corrupted');
          return;
        }

        try {
          await this.getRecords(result);
        } catch (error) {
          notifyError(
            'An error ocurred parsing your submission, please download & use the latest template'
          );
        }

        this.overlay = false;
      };
      reader.readAsArrayBuffer(file);
    },

    chooseFiles() {
      document.getElementById('fileUpload').click();
    },

    async getRecords(buffer) {
      const book = new ExcelJS.Workbook();
      const workbook = await book.xlsx.load(buffer);

      const [worksheet] = workbook.worksheets;

      const rangedData = selectSheetRange(worksheet, 'A:AF');

      const [row] = rangedData || [];
      if (!row?.length || row.length !== 32) {
        notifyError('Invalid template');
        return;
      }

      const [tagNumberColumn] = row;
      const [notesColumn] = row.reverse();

      if (tagNumberColumn !== 'TAG NUMBER' || notesColumn !== 'NOTES') {
        notifyError('Invalid template');
        return;
      }

      const records = [];
      this.userEmails = [];
      this.confirmedRecords = [];
      for (const [rowIndex, row] of rangedData.entries()) {
        if (rowIndex < 2) {
          continue;
        }

        const record = {
          ...new CrewViewModel(),
          ...new CaptureViewModel(),
          ...new CoordinatesViewModel(),
          ...{ boatName: null }
        };

        for (const [columnIndex, cellValue] of row.entries()) {
          switch (columnIndex) {
            case 0: {
              const haveBF = String(cellValue).startsWith('BF');
              record.number = cellValue;

              if (cellValue && !haveBF) {
                record.number = 'BF' + cellValue;
              }
              break;
            }

            case 1:
              record.capture.ocean = cellValue;
              break;

            case 2:
              record.capture.fish.specie.name = cellValue;
              break;

            case 3: {
              if (cellValue) {
                const date = fixTimezone(cellValue);
                record.createdDate = formatDate(date);
              }

              record.enteredDate = new Date();
              break;
            }

            case 4:
              record.coordinates.latitude.degrees = cellValue;
              break;

            case 5:
              record.coordinates.latitude.minutes = cellValue;
              break;

            case 6:
              record.coordinates.latitude.hemisphere = cellValue;
              break;

            case 7:
              record.coordinates.longitude.degrees = cellValue;
              break;

            case 8:
              record.coordinates.longitude.minutes = cellValue;
              break;

            case 9:
              record.coordinates.longitude.hemisphere = cellValue;
              break;

            case 10:
              record.coordinates.description = cellValue;
              record.locality = cellValue;
              break;

            case 11:
              record.capture.fish.length.value = cellValue;
              break;

            case 12:
              record.capture.fish.length.unit = cellValue;
              break;

            case 13:
              record.capture.fish.length.type = cellValue;
              break;

            case 14:
              record.capture.fish.length.determination = cellValue;
              break;

            case 15:
              record.capture.fish.weight.value = cellValue;
              break;

            case 16:
              record.capture.fish.weight.unit = cellValue;
              break;

            case 17:
              record.capture.fish.weight.type = cellValue;
              break;

            case 18:
              record.capture.fish.weight.determination = cellValue;
              break;

            case 19:
              record.capture.bait.name = cellValue;
              break;

            case 20:
              record.capture.fish.condition.name = cellValue;
              break;

            case 21: {
              const totalMinutes = parseInt(cellValue) || 0;
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;

              record.capture.fish.fight.hours = hours;
              record.capture.fish.fight.minutes = minutes;
              break;
            }

            case 22:
              record.capture.hook.name = cellValue;
              break;

            case 23:
              record.capture.gear.name = cellValue || 'ROD AND REEL';
              break;

            case 24:
              record.capture.tournament.name = cellValue;
              break;

            case 25:
              record.boat.name = cellValue;
              record.boatName = cellValue;
              break;

            case 26:
              record.sharkEncounter = /yes/i.test(cellValue);
              break;

            case 27:
              if (cellValue) {
                this.userEmails.push(cellValue);
              }

              record.angler.id = cellValue;
              break;

            case 28:
              if (cellValue) {
                this.userEmails.push(cellValue);
              }

              record.captain.id = cellValue;
              break;

            case 29:
              if (cellValue) {
                this.userEmails.push(cellValue);
              }

              record.firstMate.id = cellValue;
              break;

            case 30:
              if (cellValue) {
                this.userEmails.push(cellValue);
              }
              record.secondMate.id = cellValue;
              break;

            case 31:
              record.capture.notes = cellValue;
              break;

            default:
              break;
          }
        }

        records.push(record);
      }

      await this.assignRecords(records);

      if (this.erroredRows) {
        this.onlyErrored = true;
        notifyError(
          `Please, resolve first the found errors in order to continue with your submission. You can also export the Error Summary that can help you to locate and correct the errors in your file.`,
          'The system have detected errors on your submission'
        );
      } else if (this.unconfirmedRows) {
        this.onlyUnconfirmed = true;
        notifyConfirmation(
          "Your submission is almost ready, please first confirm the highlighted parameters for every unconfirmed record. Every confirmed value will be populated through the record's list",
          'Confirmation',
          () => {},
          () => {},
          'Proceed',
          '',
          false,
          false
        );
      }
    },

    async assignRecords(records = null) {
      if (this.sheet) {
        this.email = null;
        this.sheet = false;
      }

      if (!records) {
        await this.fillUsers(this.records);
        this.records = [...this.records];
        return;
      }

      await this.fillUsers(records);
      this.records = [...records];
    },

    getFormatted(date) {
      return formatDate(date);
    },

    getFullName(item) {
      const { firstName, lastName } = item || {};
      return [firstName, lastName].filter(Boolean).join(' ').toUpperCase();
    },

    getBoatName(data) {
      const { boat, boatName } = data || {};
      return boat?.name || boatName;
    },

    getFishStat(data) {
      const { value, unit, determination, type } = data || {};

      if (!value && Number(value) !== 0) {
        return null;
      }

      const measure = [value, unit].filter(Boolean).join(' ');
      return [measure, type, determination].filter(Boolean).join(', ');
    },

    getFightTime(data) {
      const { hours, minutes } = data?.capture?.fish?.fight || {};

      if (hours) {
        return [hours, minutes]
          .filter(Boolean)
          .map((value) => (value < 10 ? '0' + value : value))
          .join(':')
          .concat('h');
      }

      if (!minutes) {
        return null;
      }

      return (minutes < 10 ? '0' + minutes : minutes) + 'min';
    },

    getCoordinates(data) {
      const { coordinates } = data || {};

      const areValid = validCoordinates(coordinates);

      if (!areValid) {
        return null;
      }

      const { latitude, longitude } = coordinates || {};

      const latitudeStr = `${latitude['degrees']}° ${latitude['minutes']}' ${latitude['hemisphere']}`;
      const longitudeStr = `${longitude['degrees']}° ${longitude['minutes']}' ${longitude['hemisphere']}`;

      return `${latitudeStr} / ${longitudeStr}`;
    },

    validateCoordinates(data) {
      const { coordinates } = data || {};

      return validCoordinates(coordinates);
    },

    haveError(index, type = null) {
      let filter = (record) => record.row === index + 3;

      if (!type) {
        return this.errors.some(filter);
      }

      filter = (record) => record.row === index + 3 && record.type === type;
      return this.errors.some(filter);
    },

    isConfirmed(recordIndex, type = null) {
      if (!recordIndex && recordIndex !== 0) {
        return false;
      }

      if (!type) {
        return this.confirmedRecords.some(
          ({ index, description }) =>
            recordIndex === index && description === 'LBT'
        );
      }

      switch (type) {
        case 'location':
          return this.confirmedRecords.some(
            ({ index, description }) =>
              recordIndex === index && description.includes('L')
          );

        case 'boat':
          return this.confirmedRecords.some(
            ({ index, description }) =>
              recordIndex === index && description.includes('B')
          );

        case 'tournament':
          return this.confirmedRecords.some(
            ({ index, description }) =>
              recordIndex === index && description.includes('T')
          );

        default:
          return false;
      }
    },

    checkOceanAndSpecies(index, type, record) {
      const specie = String(record.capture.fish.specie.name).toUpperCase();
      const ocean = String(record.capture.ocean).toUpperCase();

      const WM = specie === 'WHITE MARLIN';
      const BK = specie === 'BLACK MARLIN';
      const ST = specie === 'STRIPED MARLIN';

      if (ocean === 'PACIFIC' && WM) {
        this.errors.push({
          row: index + 1,
          error:
            'White Marlin is not allowed to be set along side the Pacific Ocean',
          type
        });
      }

      if (ocean === 'INDIAN' && WM) {
        this.errors.push({
          row: index + 1,
          error:
            'White Marlin is not allowed to be set along side the Indian Ocean',
          type
        });
      }

      if (ocean === 'ATLANTIC' && BK) {
        this.errors.push({
          row: index + 1,
          error:
            'Black Marlin is not allowed to be set along side the Atlantic Ocean',
          type
        });
      }

      if (ocean === 'ATLANTIC' && ST) {
        this.errors.push({
          row: index + 1,
          error:
            'Striped Marlin is not allowed to be set along side the Atlantic Ocean',
          type
        });
      }
    },

    async checkRecord(record, index) {
      if (!record.number) {
        this.errors.push({
          row: index + 1,
          error: 'Missing Tag Number',
          type: 'tag_number'
        });
      } else {
        const response = await this.checkEvent({
          payload: { number: record.number },
          event: 'tags'
        });

        if (response?.data?.docs) {
          this.errors.push({
            row: index + 1,
            error: 'Tag Number already exists',
            type: 'tag_number'
          });
        }
      }

      if (!record.capture.ocean) {
        this.errors.push({
          row: index + 1,
          error: 'Missing Ocean',
          type: 'ocean'
        });
      }

      if (!record.capture.fish.specie.name) {
        this.errors.push({
          row: index + 1,
          error: 'Missing Species',
          type: 'species'
        });
      } else {
        this.checkOceanAndSpecies(index, 'species', record);
      }

      if (!record.createdDate) {
        this.errors.push({
          row: index + 1,
          error: 'Missing Tag Date',
          type: 'tag_date'
        });
      }

      if (record.createdDate) {
        const date = moment(record.createdDate);
        const valid = date.isValid();

        if (!valid) {
          this.errors.push({
            row: index + 1,
            error: 'Invalid Tag Date',
            type: 'tag_date'
          });
        }
      }

      const valid = this.validateCoordinates(record);
      if (!valid) {
        this.errors.push({
          row: index + 1,
          error: 'Invalid Coordinates',
          type: 'coordinates'
        });
      }

      if (!record.coordinates.description || !record.locality) {
        this.errors.push({
          row: index + 1,
          error: 'Missing Location Description',
          type: 'location'
        });
      }
    },

    assignConfirmed(record, recordIndex) {
      if (!recordIndex && recordIndex !== 0) {
        return false;
      }

      const confirmed = this.isConfirmed(recordIndex);
      if (confirmed) {
        return;
      }

      const confirmedIndex = this.confirmedRecords.findIndex(
        (item) => item.index === recordIndex
      );
      let confirmedDescription = '';
      if (confirmedIndex >= 0) {
        confirmedDescription =
          this.confirmedRecords[confirmedIndex].description;
      }
      let description = '';

      const confirmedLocation =
        confirmedDescription?.includes('L') ||
        this.records.some(({ coordinates }, index) => {
          const { latitude, longitude, description } = coordinates;
          const isConfirmed = this.isConfirmed(index, 'location');

          if (isConfirmed) {
            const sameDescription =
              String(record.coordinates.description).toUpperCase() ===
              String(description).toUpperCase();

            const sameLatitude = Object.keys(latitude).every(
              (key) => latitude[key] === record.coordinates.latitude[key]
            );
            const sameLongitude = Object.keys(longitude).every(
              (key) => longitude[key] === record.coordinates.longitude[key]
            );

            return sameDescription && sameLatitude && sameLongitude;
          }

          return false;
        });

      if (confirmedLocation) {
        description += 'L';
      }

      const confirmedBoat =
        !record.boatName ||
        confirmedDescription?.includes('B') ||
        this.records.some(({ boatName }, index) => {
          const isConfirmed = this.isConfirmed(index, 'boat');

          if (isConfirmed) {
            return (
              String(record.boatName).toUpperCase() ===
              String(boatName).toUpperCase()
            );
          }

          return false;
        });

      if (confirmedBoat) {
        description += 'B';
      }

      const confirmedTournament =
        !record.capture.tournament.name ||
        confirmedDescription?.includes('T') ||
        this.records.some(({ capture }, index) => {
          const isConfirmed = this.isConfirmed(index, 'tournament');

          if (isConfirmed) {
            return (
              String(record.capture.tournament.name).toUpperCase() ===
              String(capture.tournament.name).toUpperCase()
            );
          }

          return false;
        });

      if (confirmedTournament) {
        description += 'T';
      }

      if (!description) {
        return;
      }

      if (confirmedIndex >= 0) {
        this.confirmedRecords[confirmedIndex].description = description;
      } else {
        this.confirmedRecords.push({ index: recordIndex, description });
      }
    },

    async fillUsers(records) {
      const response = await this.getExistentUsers({ emails: this.userEmails });
      const users = response?.data?.docs || [];
      this.errors = [];

      for (const [index, record] of records.entries()) {
        this.assignConfirmed(record, index);
        await this.checkRecord(record, index + 2);

        for (const crew_member of [
          'angler',
          'captain',
          'firstMate',
          'secondMate'
        ]) {
          const user = record[crew_member] || {};
          if (user?.firstName) {
            continue;
          }

          if (!user?.id) {
            record[crew_member] = new CrewViewModel()[crew_member];
            continue;
          }

          const userData = users.find(({ email }) => email === user.id);

          if (!userData) {
            this.errors.push({
              row: index + 3,
              error: `${crew_member.toUpperCase()} email not found in the system, add new profile using UI`,
              type: crew_member
            });
            continue;
          }

          const data = { ...userData };
          delete data.email;
          record[crew_member] = data;
        }
      }
    },

    exportSummary() {
      const doc = new jsPDF({ format: 'letter', putOnlyUsedFonts: true });
      doc.setDocumentProperties({ title: `Error Summary - ${this.fileName}` });
      autoTable(doc, {
        head: [['Error Summary']],
        headStyles: { fontSize: 14, fillColor: [255, 92, 0] },
        body: [[this.fileName]],
        bodyStyles: { fontSize: 12 }
      });

      const affectedRows = [...new Set(this.errors.map(({ row }) => row))];
      affectedRows.sort((a, b) => a - b);

      for (const row of affectedRows) {
        const errors = [...this.errors].filter((item) => item.row === row);
        const body = [];

        for (const item of errors) {
          const { error, type } = item;
          body.push([String(type).toUpperCase(), error]);
        }

        autoTable(doc, {
          head: [
            [{ content: `Row #${row}` }, { content: 'Error Description' }]
          ],
          body,
          columns: [
            { header: `Row #${row}`, dataKey: 'type' },
            { header: 'Error Description', dataKey: 'error' }
          ],
          columnStyles: { 0: { cellWidth: 40 } }
        });
      }

      doc.save(`Error Summary - ${moment().toISOString()}.pdf`);
    },

    async submitBatch() {
      this.overlay = true;

      try {
        const response = await this.addTag(this.records);
        const { success, message } = response?.data || {};
        if (success) {
          notifyConfirmation(
            'Records were successfully registered',
            'All done!'
          );
          this.showDialog = false;
        } else {
          notifyError(message);
        }
      } catch (error) {
        notifyError("Records weren't registered", 'Unable to save');
      }

      this.overlay = false;
    },

    editRecord(index) {
      this.openEdit = true;

      setTimeout(async () => {
        if (this.$refs.contentCard) {
          this.$refs.contentCard.scrollTop = 0;
        }

        const record = window.structuredClone({
          ...this.records[index],
          index
        });

        if (this.$refs.tagsForm) {
          await this.$refs.tagsForm.initRecord(record);
        }
      }, 50);
    },

    async submitRecord() {
      const input = this.$refs.tagsForm.submit();
      const { data, valid } = input || {};

      if (!valid) {
        notifyError('Please, fill all required fields');
        return;
      }

      const response = await this.checkEvent({
        payload: { number: data.number },
        event: 'tags'
      });

      if (response?.data?.docs) {
        notifyError('Tag Number already exists');
        return;
      }

      const record = this.confirmedRecords.find(
        ({ index }) => index === data.index
      );

      let description = 'LBT';
      const { boat, tournament } = data || {};

      if (!(tournament?.id || tournament?._id)) {
        description = description.replace('T', '');
      }

      if (!(boat?.id || boat?._id)) {
        description = description.replace('B', '');
      }

      if (!record) {
        this.confirmedRecords.push({ index: data.index, description });
      } else {
        if (!record.description.includes('L')) {
          record.description = description;
        }
      }

      const index = data.index;
      delete data.index;
      this.records[index] = data;

      await this.fillUsers(this.records);
      this.openEdit = false;
    }
  }
};
</script>

<style scoped>
.bg-red {
  background-color: lightcoral;
}

.import-wrapper {
  border: 3px dashed lightgray;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-container {
  display: flex;
  flex-direction: row;
  background-color: aqua;
}

.row-indicator {
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.row-confirmation {
  position: absolute;
  right: 90px;
  bottom: 10px;
}

.row-confirmation >>> button {
  border-radius: 15px;
  height: 32px !important;
}

.btn-chip {
  cursor: pointer;
}
</style>
