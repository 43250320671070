<template>
  <v-dialog
    v-model="open"
    max-width="800px"
    height="600px"
    scrollable
    persistent
    :fullscreen="full"
  >
    <v-card title>
      <v-card-title>
        <h3>Verify your selection</h3>
        <v-spacer />
        <v-btn v-on:click="closeVerification" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-3">
        <v-banner color="light-blue lighten-5 px-5">
          <!-- <v-icon slot="icon" color="primary" size="36"> mdi-link-lock </v-icon> -->
          Please, verify and confirm every parameter in this section. Use the
          "Create as new" checkbox to register your input if you can't find the
          right data in the dropdowns.
        </v-banner>

        <v-form ref="verificationForm">
          <div class="my-5" v-if="coordinates && !confirmedLocation">
            <v-autocomplete
              :items="locations"
              :loading="loadingLocations"
              label="Location Description"
              v-model="location"
              item-text="name"
              item-value="name"
              hide-details
              return-object
              outlined
              dense
              clearable
              :disabled="newLocation"
              :rules="[(v) => !!v || newLocation || 'Required']"
              @change="() => $refs.verificationForm.validate()"
            />
            <span>
              Based on your input choose the closest location in the drop down
            </span>
            <v-checkbox
              v-model="newLocation"
              :label="`Create as new: ${description} (${coordinates})`"
              color="primary"
              class="font-weight-thin text-caption"
              :rules="location ? undefined : [(v) => v || 'Required']"
              hide-details
            ></v-checkbox>
          </div>

          <v-divider></v-divider>

          <div class="my-5" v-if="boatName && !confirmedBoat">
            <v-autocomplete
              :items="boats"
              :loading="loadingBoats"
              label="Boat"
              v-model="boat"
              item-text="name"
              item-value="name"
              hide-details
              return-object
              outlined
              dense
              clearable
              @click:clear="() => getBoats()"
              :disabled="newBoat"
              :rules="[(v) => !!v || newBoat || 'Required']"
              :search-input.sync="searchBoat"
            />
            <span> Use the dropdown to make your selection </span>
            <v-checkbox
              v-model="newBoat"
              :label="`Create as new: ${boatName}`"
              color="primary"
              class="font-weight-thin text-caption"
              :rules="boat ? undefined : [(v) => v || 'Required']"
              hide-details
            ></v-checkbox>
          </div>

          <div class="my-5" v-if="tournamentName && !confirmedTournament">
            <v-autocomplete
              :items="tournaments"
              :loading="loadingTournaments"
              label="Tournament"
              v-model="tournament"
              item-text="name"
              item-value="name"
              hide-details
              return-object
              outlined
              dense
              clearable
              @click:clear="() => getTournaments()"
              :disabled="newTournament"
              :rules="[(v) => !!v || newTournament || 'Required']"
              :search-input.sync="searchTournament"
            />
            <span> Use the dropdown to make your selection </span>
            <v-checkbox
              v-model="newTournament"
              :label="`Create as new: ${tournamentName}`"
              color="primary"
              class="font-weight-thin text-caption"
              :rules="tournament ? undefined : [(v) => v || 'Required']"
              hide-details
            ></v-checkbox>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text outlined color="primary" @click="confirmData">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { validCoordinates } from '../../../../helpers/map';
import { notifyMessage } from '../../../../helpers/notifications';

export default {
  name: 'batch-item-verification',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dataType: {
      type: String,
      default: null
    },
    value: {
      type: [String, Object],
      default: null
    },
    confirmedLocation: {
      type: Boolean,
      default: false
    },
    confirmedBoat: {
      type: Boolean,
      default: false
    },
    confirmedTournament: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    location: null,
    locations: [],
    loadingLocations: false,
    newLocation: false,

    boat: null,
    boats: [],
    loadingBoats: false,
    newBoat: false,
    boatText: null,

    tournament: null,
    tournaments: [],
    loadingTournaments: false,
    newTournament: false,
    tournamentText: null
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    searchBoat: {
      get: function () {
        return this.boatText;
      },
      set: async function (value) {
        if (value) {
          await this.getBoats(value);
        }
      }
    },
    searchTournament: {
      get: function () {
        return this.tournamentText;
      },
      set: async function (value) {
        if (value) {
          await this.getTournaments(value);
        }
      }
    },
    tournamentName() {
      return this.value?.capture?.tournament?.name || null;
    },
    boatName() {
      const { boat, boatName } = this.value || {};
      return boat?.name || boatName || null;
    },
    description() {
      const { locality, coordinates } = this.value || {};
      return coordinates?.description || locality || null;
    },
    coordinates() {
      const areValid = validCoordinates(this.value?.coordinates);

      if (!areValid) {
        return null;
      }

      const { latitude, longitude } = this.value?.coordinates || {};

      const latitudeStr = `${latitude['degrees']}° ${latitude['minutes']}' ${latitude['hemisphere']}`;
      const longitudeStr = `${longitude['degrees']}° ${longitude['minutes']}' ${longitude['hemisphere']}`;

      return `${latitudeStr} / ${longitudeStr}`;
    }
  },
  watch: {
    coordinates: {
      handler: 'searchLocations'
    },
    boatName: {
      handler: 'searchBoats'
    },
    tournamentName: {
      handler: 'searchTournaments'
    }
  },
  methods: {
    closeVerification() {
      if (this.$refs.verificationForm) {
        this.$refs.verificationForm.reset();
      }

      this.$emit('close');
    },

    ...mapActions('entry', ['addItem', 'saveLocation']),
    async confirmData() {
      if (this.$refs.verificationForm) {
        const valid = this.$refs.verificationForm.validate();

        if (valid) {
          let description = 'LBT';
          if (this.newLocation) {
            try {
              const name = String(this.description).toUpperCase();
              const response = await this.saveLocation({
                name,
                ocean: this.value?.capture?.ocean,
                coordinates: this.value?.coordinates,
                approved: false
              });

              const { success, message } = response?.data || {};

              if (success) {
                this.location = { name };
              } else {
                description = description.replace(/l/i, '');
                notifyMessage(message);
              }
            } catch (error) {
              description = description.replace(/l/i, '');
              notifyMessage('Unable to register the location');
            }
          }

          if (this.newBoat) {
            try {
              const name = String(this.boatName).toUpperCase();
              const response = await this.addItem({
                item: 'boats',
                data: { name }
              });

              const { success, message } = response?.data || {};

              if (success) {
                this.boat = { name };
              } else {
                description = description.replace(/b/i, '');
                notifyMessage(message);
              }
            } catch (error) {
              description = description.replace(/b/i, '');
              notifyMessage('Unable to register the boat');
            }
          }

          if (this.newTournament) {
            try {
              const name = String(this.tournamentName).toUpperCase();
              const response = await this.addItem({
                item: 'tournaments',
                data: { name }
              });

              const { success, message } = response?.data || {};

              if (success) {
                this.tournament = { name };
              } else {
                description = description.replace(/t/i, '');
                notifyMessage(message);
              }
            } catch (error) {
              description = description.replace(/t/i, '');
              notifyMessage('Unable to register the tournament');
            }
          }

          this.$emit('done', {
            location: this.location?.name || null,
            boat: this.boat?.name || null,
            tournament: this.tournament?.name || null,
            description
          });

          this.$refs.verificationForm.reset();
        }
      }
    },

    ...mapActions('query', ['getLocation']),
    async getLocations(name = null, nearest = false) {
      this.loadingLocations = true;

      const payload = { name, page: 1, limit: 10, type: 'all' };
      if (nearest) {
        payload['coordinates'] = this.value.coordinates;
      }

      const response = await this.getLocation(payload);
      this.locations = response?.data?.docs || [];
      this.loadingLocations = false;
    },
    async searchLocations(value) {
      if (value) {
        await this.getLocations(null, true);
      } else {
        await this.getLocations();
      }
    },

    ...mapActions('query', ['getItems']),
    getArray(data) {
      return Object.keys(data || {}).map((doc) => ({
        name: data[doc],
        abbr: doc
      }));
    },
    async getBoats(name = '') {
      this.loadingBoats = true;

      const payload = {
        name,
        item: 'boats',
        page: 1,
        limit: 10
      };

      const response = await this.getItems(payload);
      this.boats = [];

      const { docs } = response?.data || {};
      if (docs) {
        this.boats = Array.isArray(docs) ? docs : this.getArray(docs);
      }

      if (name) {
        if (this.boats.length) {
          this.boat = this.boats[0];
        } else {
          await this.getBoats();
        }
      }

      this.loadingBoats = false;
    },
    async getTournaments(name = '') {
      this.loadingTournaments = true;

      const payload = {
        name,
        item: 'tournaments',
        page: 1,
        limit: 10
      };

      const response = await this.getItems(payload);
      this.tournaments = [];

      const { docs } = response?.data || {};
      if (docs) {
        this.tournaments = Array.isArray(docs) ? docs : this.getArray(docs);
      }

      if (name) {
        if (this.tournaments.length) {
          this.tournament = this.tournaments[0];
        } else {
          await this.getTournaments();
        }
      }

      this.loadingTournaments = false;
    },
    async searchBoats(value) {
      if (this.open) {
        this.searchBoat = value;
      }
    },
    async searchTournaments(value) {
      if (this.open) {
        this.searchTournament = value;
      }
    }
  }
};
</script>

<style scoped>
.v-card__text {
  min-height: 400px !important;
}

.section {
  padding: 10px 0;
  margin: 10px 0;
}
</style>
