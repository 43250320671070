<template>
  <transition name="fade">
    <v-banner color="amber lighten-5" single-line v-if="pending">
      <v-icon slot="icon" color="warning" size="36">
        mdi-alert-decagram
      </v-icon>
      <h5>There are location records pending for your approval</h5>
      <template v-slot:actions>
        <v-btn outlined color="warning" small @click="navigate">
          Take action
        </v-btn>
        <v-btn color="warning" icon @click="pending = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-banner>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'pending-locations-banner',
  data: () => ({
    pending: false,
    to: '/edit?type=locations&status=pending'
  }),
  async mounted() {
    await this.getPendingLocations();
  },
  methods: {
    ...mapActions('query', ['getItems']),
    async getPendingLocations() {
      const { data: pages } =
        (await this.getItems({
          item: 'locations',
          type: 'pending',
          page: 1,
          limit: 10
        })) || {};
      this.pending = !!pages?.totalDocs;
    },
    navigate() {
      if (this.$route.fullPath !== this.to) {
        this.$router.push(this.to);
        this.pending = false;
        return;
      }

      this.$router.go();
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
