<template>
  <v-list-item>
    <v-list-item-content class="py-0">
      <v-list-item-title
        :class="{
          'red lighten-5': error,
          'orange lighten-5': warning
        }"
      >
        <span v-if="editable">
          <v-icon
            :class="{ 'white--text': error }"
            v-if="disabled"
            @click="disabled = false"
          >
            mdi-square-edit-outline
          </v-icon>
          <v-icon :class="{ 'white--text': error }" v-else @click="updateValue">
            mdi-check-circle-outline
          </v-icon>
        </span>
        {{ label }}
        <span class="text-caption ml-1" v-if="type === 'user' && error">
          (Click
          <v-icon
            color="red"
            size="14px"
            class="mx-0 px-0"
            @click="$emit('new')"
          >
            mdi-badge-account-alert-outline </v-icon
          >)
        </span>
      </v-list-item-title>
      <v-list-item-subtitle class="font-weight-thin text-body-2 black-text">
        <v-menu
          v-model="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
          v-if="type === 'date'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="my-0 py-0 font-weight-thin text-body-2"
              hide-details="auto"
              v-model="formattedDate"
              prepend-icon="mdi-calendar"
              readonly
              :disabled="disabled"
              v-bind="attrs"
              v-on="on"
              v-if="!disabled"
            />
            <v-text-field
              class="my-0 pt-1 font-weight-thin text-body-2"
              hide-details="auto"
              v-model="formattedDate"
              disabled
              v-else
            />
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
            color="primary"
            no-title
          />
        </v-menu>
        <v-autocomplete
          class="my-0 py-0 font-weight-thin text-body-2"
          :items="docs"
          v-model="model"
          hide-details="auto"
          item-text="name"
          :error="error"
          :disabled="disabled"
          placeholder="Not Specified"
          append-icon=""
          clearable
          v-else-if="type === 'list'"
        >
          <template v-slot:no-data>
            <div class="text-caption mx-5" v-text="'Type to search'" />
          </template>
        </v-autocomplete>
        <v-text-field
          class="my-0 py-0 font-weight-thin text-body-2"
          hide-details="auto"
          v-model="model"
          :error="error"
          :disabled="disabled"
          placeholder="Not Specified"
          clearable
          v-else-if="type === 'text'"
        />
        <v-text-field
          class="my-0 pt-0 font-weight-thin text-body-2"
          hide-details="auto"
          v-model="user"
          disabled
          placeholder="Not Specified"
          v-else-if="type === 'user'"
        >
          <template v-slot:prepend-inner v-if="error">
            <div class="icon" @click="$emit('new')">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red" v-bind="attrs" v-on="on">
                    mdi-badge-account-alert-outline
                  </v-icon>
                </template>
                <span> User not found, click here to create a profile </span>
              </v-tooltip>
            </div>
          </template>
        </v-text-field>
        <v-text-field
          class="my-0 pt-0 font-weight-thin text-body-2"
          hide-details="auto"
          v-model="coords"
          disabled
          placeholder="Invalid coordinates"
          v-else-if="type === 'coordinates'"
        >
          <template v-slot:prepend-inner v-if="coords">
            <div class="icon" @click="$emit('confirm')">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="orange" v-bind="attrs" v-on="on">
                    mdi-map-marker-alert-outline
                  </v-icon>
                </template>
                <span>
                  Click here to validate your entry against registered locations
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-text-field>
        <v-text-field
          class="my-0 pt-0 font-weight-thin text-body-2"
          hide-details="auto"
          v-model="model"
          disabled
          placeholder="Not Specified"
          v-else
        />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex';
import { fixTimezone } from '../../helpers/format';
import moment from 'moment';
import { validCoordinates } from '../../helpers/map';
export default {
  name: 'batch-import',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: [String, Object, Date],
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    action: {
      type: Function,
      default: () => {}
    },
    needConfirmation: {
      type: Boolean,
      default: false
    },
    confirmed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    warning() {
      return (
        this.needConfirmation &&
        !this.error &&
        !this.confirmed &&
        (this.model || this.coords)
      );
    },
    model: {
      get: function () {
        return this.value;
      },
      set: async function (value) {
        this.$emit('input', value);
      }
    },
    date: {
      get: function () {
        const date = fixTimezone(this.value);
        return date ? date.format('YYYY-MM-DD') : null;
      },
      set: function (value) {
        this.$emit('input', value);
      }
    },
    formattedDate: {
      get: function () {
        if (this.value) {
          const date = fixTimezone(this.value);
          const result = date ? date.format('MM/DD/YYYY') : null;
          return result;
        }
        return null;
      },
      set: function (value) {
        const date_regex =
          /([1-9]|0[1-9]|1[012])[- /.]([1-9]|0[1-9]|[12][0-9]|3[01])[- /.]\d\d\d\d/;
        if (date_regex.test(value)) {
          const date = moment(value, 'MM/DD/YYYY');
          this.model = date.isValid() ? date.format('YYYY-MM-DD') : null;
        } else this.model = null;
      }
    },
    user() {
      const { firstName, lastName, id } = this.value || {};
      return (
        [firstName, lastName].filter(Boolean).join(' ').toUpperCase() || id
      );
    },
    coords() {
      const areValid = validCoordinates(this.value);

      if (!areValid) {
        return null;
      }

      const { latitude, longitude } = this.value || {};

      const latitudeStr = `${latitude['degrees']}° ${latitude['minutes']}' ${latitude['hemisphere']}`;
      const longitudeStr = `${longitude['degrees']}° ${longitude['minutes']}' ${longitude['hemisphere']}`;

      return `${latitudeStr} / ${longitudeStr}`;
    }
  },
  data: () => ({
    loading: false,
    docs: [],
    menu: false,
    rule: [(v) => !!v || 'Required'],
    disabled: true
  }),
  methods: {
    ...mapActions('query', ['getItems']),
    async getList(item) {
      const response = await this.getItems({
        item,
        page: 1,
        limit: 20
      });
      this.docs = response?.data?.docs || [];
    },
    async onValueChange() {
      const itemType = this.label.toLowerCase();

      if (itemType === 'ocean') {
        this.docs = ['ATLANTIC', 'PACIFIC', 'INDIAN'];
        return;
      }

      if (itemType === 'species') {
        await this.getList(itemType);
        return;
      }
    },
    updateValue() {
      this.disabled = true;
      this.$emit('onUpdate');
    }
  }
  // watch: {
  //   value: {
  //     handler: 'onValueChange',
  //     immediate: true
  //   }
  // }
};
</script>

<style scoped>
.v-text-field >>> .v-text-field__slot input {
  color: black;
  padding: 0;
  margin: 0;
}

.v-list-item-subtitle >>> .v-select__slot input {
  color: black;
  padding: 0;
  margin: 0;
}

.v-text-field >>> .v-input__prepend-outer {
  margin: 0;
}

/* .v-text-field >>> .v-input__slot .v-input__prepend-inner {
  position: relative;
  top: -5px;
  cursor: pointer;
}

.v-text-field >>> .v-input__slot {
  align-items: flex-start;
} */

.v-list-item >>> .v-list-item__title {
  height: 24px;
  display: flex;
  align-items: center;
}

.v-list-item >>> .v-list-item__title .v-icon {
  position: relative;
  margin-right: 5px;
}

.v-icon {
  cursor: pointer;
}
</style>
